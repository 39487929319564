import '../styles/Banner.css';

function Banner() {
  return (
    <div className="banner">
      <img src="/header.png" alt="HGN Banner" />
    </div>
  );
}

export default Banner;
